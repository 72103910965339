/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Text, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Home"}>
        <SiteHeader set="en" currentLanguage={1} />

        <Column className="pb--50 pt--80" name={"uvod"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"}>
              
              <Image style={{"maxWidth":670}} src={"https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=860x_.png"} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/fc5e8027bfed45579a14af9598047ed3_s=2000x_.png 2000w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 w--300 lh--1" content={""}>
                    </Title>

                    <Subtitle className="subtitle-box fs--30 ls--004 lh--1 mt--0" content={"15 – 6 – 2024"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"<span style=\"color: var(--color-blend--50);\">We will be happy to celebrate our love with you.<br><br>14. 6. 2024 – we start at 15:00 at the Aria Hotel<br>15. 6. 2024 – wedding ceremony at Lobkowicz Palace, reception to follow at Villa Richter<br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ubytovani"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 swpf--uppercase" content={"<span style=\"color: rgb(205, 198, 182);\">ACCOMMODATION</span>"}>
              </Title>

              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">14. 6. 2024\n</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-blend--50);\">Accommodation is provided for you for two nights at the Aria Hotel.\n<br><br>&nbsp;Check-in will be possible from 3:00 p.m.<br>We all meet for&nbsp;welcome drink&nbsp;at 4:00 p.m.&nbsp;<br></span><a href=\"https://www.ariahotel.net/\">ARIA HOTEL</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/9665cdeb889843d3aed5fe4f7964906d_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(241,239,234,1)"}} name={"svatebni-dary"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" content={"<span style=\"color: var(--color-blend--50);\">WEDDING GIFTS</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--20" style={{"maxWidth":650}} content={"Dear friends,\n​ we guess you may have already started to think about a wedding gift idea… Most of all, we want to be happy together, but that’s something we will take care of by ourselves. We also want everyone to have a good time at our wedding, and that’s up to all of us to make sure this wish comes true.<br><br>If you still insist on giving us a present, we will be very happy if you join us and together, we will donate a financial contribution to a charity organization rescuing abandoned and abused animals.<br><br>The account number to which please send financial contributions:<br>2302651932/2010<br><br>&nbsp;IBAN CZ5520100000002302651932<br>BIC FIOBCZPPXXX<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"svatebni-den"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">WEDDING DAY</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"paddingTop":0}} name={"16e7fw9kydy"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 swpf--uppercase" content={"<span style=\"color: rgb(205, 198, 182);\">PALÁC&nbsp;Lobkowicz</span><br>"}>
              </Title>

              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">15. 6. 2024\n</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-blend--50);\">The wedding ceremony will take place in the Imperial Hall of the Lobkowicz Palace.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":577}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/fa3bebd901fa4542bc7ed5ae05d3e0c1_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wq2y67ih6g"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":587}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/bf8277fee7ac42b5820eb7058c5e3447_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/24903/bf8277fee7ac42b5820eb7058c5e3447_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/bf8277fee7ac42b5820eb7058c5e3447_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/bf8277fee7ac42b5820eb7058c5e3447_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: rgb(205, 198, 182);\">VILLA RICHTER</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: var(--color-blend--50);\">15. 6. 2024</span>"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18" style={{"maxWidth":399}} content={"<span style=\"color: var(--color-blend--50);\">We will start the celebration at Villa Richter with a cocktail in the vineyard, followed by the wedding reception.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(241,239,234,1)"}} name={"harmonogram"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" content={"<span style=\"color: var(--color-blend--50);\">TIMEPLAN</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--20" style={{"maxWidth":650}} content={"12:00 Welcome drink in the Lobkowicz Palace<br>13:00 Wedding ceremony in the Imperial Hall<br>13:30 Congratulations, toast<br>14:30 Wedding cocktail at the Villa Richter Vineyard<br>16:00 Wedding reception at Villa Richter<br>19:00 First dance<br>20:00 Cutting of the cake<br>22:00 Wedding party in Skleník<br>22:00 Evening refreshments<br>01:00 End of the celebration<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"cbqfq2ulyt9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: var(--color-blend--50);\">TRANSPORT</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingTop":31,"paddingBottom":0}} name={"doprava"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"<span style=\"color: var(--color-blend--50);\">Transportation is provided from the hotel to the ceremony.<br><span style=\"font-style: italic;\"><br>Detailed information will be added.</span></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"marginTop":102}} name={"nx7w9hk1dfg"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginTop":0,"paddingLeft":0,"paddingRight":0,"paddingBottom":0,"backgroundColor":"rgba(205,198,182,1)"}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"marginTop":0,"paddingBottom":0}} name={"f88ruqlrv1t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">DRESS CODE</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dress-code"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: var(--color-custom-1);\">1st DAY</span>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"color: var(--color-blend--50);\">SMART CASUAL<br></span>On this day, we would like to welcome you in white and black or choose one of these colors. The style is smart casual, mainly to make you feel comfortable.<br>"}>
              </Text>

              <Image style={{"maxWidth":242}} alt={""} src={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box fs--36" content={"<span style=\"color: var(--color-custom-1);\">2nd DAY</span>"}>
              </Title>

              <Text className="text-box ff--3" content={"<span style=\"color: var(--color-blend--50);\">COCKTAIL CHIC<br></span>Not white and not black, choose cocktail chic. Only the bride should wear white, and the groom should wear black, but the final choice is up to you. Villa Richter's terrain is cube-shaped, so please be careful if you want to wear high heels. We recommend changing shoes after ceremony.<br>"}>
              </Text>

              <Image style={{"maxWidth":242}} alt={""} src={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/cd65a835b23140288cb09b4845540afc_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"galerie"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/6d89a23ef8b547cf939942681a6dbe48_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/6d89a23ef8b547cf939942681a6dbe48_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/6d89a23ef8b547cf939942681a6dbe48_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/6d89a23ef8b547cf939942681a6dbe48_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/24903/eeb876cd0fa44b0d8ffda3b7537b4c4e_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/24903/eeb876cd0fa44b0d8ffda3b7537b4c4e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/24903/eeb876cd0fa44b0d8ffda3b7537b4c4e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/24903/eeb876cd0fa44b0d8ffda3b7537b4c4e_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":40,"paddingBottom":0}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"<span style=\"color: rgb(205, 198, 182);\">CONTACT</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--80" style={{"paddingBottom":54}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1);\">ARIA HOTEL</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Tržiště 9<br>118 00 Praha - Malá Strana<br>+420 225 334 111</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" style={{"marginTop":0}} name={"04r7qnyxso2c"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--80" style={{"paddingTop":44}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: rgb(235, 232, 225);\">PALÁC LOBKOWICZ</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Jiřská 3<br>119 00 Praha 1&nbsp;- Malá Strana<br>+420 702 201 145</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"qnq4w1pezj"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--80" style={{"paddingTop":44}} name={"id2kojr5bai"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"color: var(--color-custom-1);\">VILLA RICHTER</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 lh--2" style={{"maxWidth":270}} content={"<span style=\"color: var(--color-blend--50);\">Staré zámecké schody 6/251&nbsp;<br>118 00</span>&nbsp;<span style=\"color: var(--color-blend--50);\">Praha - Hradčany<br>+420 702 282 402</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"m73ni9vwxl8"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":23,"paddingBottom":0}} name={"5yjhc6243fv"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654}} content={"<span style=\"color: var(--color-blend--50);\">In case of further questions, please contact our wedding coordinator.<br>Eva: eva@ifconcept.cz\n</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":65,"marginBottom":0,"paddingBottom":0}} name={"nqjp20guna"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":1244}} alt={""} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80" style={{"paddingTop":0}} name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"We look forward to you!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/galerie\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}